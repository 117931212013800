import { Modal, Button, Text } from "@mantine/core";
import { COLORS } from "../../constants/theme";

function AlertModal({ modalOpened, resetModal, title, message }: any) {
  return (
    <>
      <Modal
        opened={modalOpened}
        onClose={resetModal}
        size="auto"
        title={title}
        styles={{
          modal: {
            zIndex: 1050, // Increase z-index for modal
          },
          overlay: {
            zIndex: 1049, // Increase z-index for overlay if necessary
          },
        }}
      >
        <Text
          style={{
            whiteSpace: "pre-wrap",
          }}
          mb={5}
        >
          {message}
        </Text>

        <div className="flex-center">
          <Button
            styles={{
              root: {
                backgroundColor: COLORS.greenPrimary,
                color: "white",
                "&:hover": {
                  backgroundColor: COLORS.greenPrimary,
                },
              },
            }}
            onClick={resetModal}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AlertModal;
