import {
  ActionIcon,
  Checkbox,
  LoadingOverlay,
  Select,
  Text,
  Modal,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import CustomTabs from "../../common/PageLayout/components/CustomTabs";
import { COLORS } from "../../constants/theme";
import { formatCurrency } from "../../modules";
import { useAuth } from "../../contexts";
import { filterContacts } from "../../repositories/contactRepository";
import _ from "lodash";
import { SelectStyles } from "../../constants/styledComponents";
import {
  deleteTransactions,
  mapTxnsToContacts,
} from "../../repositories/transactions";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPaperclip,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useBanksContext } from "./context/BanksContext";
import { Button, IconButton } from "rsuite";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { configs, ICONS, IMAGES } from "../../constants";
import UploadCSVModal from "../Cashflow/components/EditableTableRow/UploadCSVModal";
import SelectContact from "./components/SelectContact";
import Card from "../../common/Card/Card";
import { ROUTES } from "../../constants/routes";
import axios from "axios";
import { getHighlightedText } from "../../modules/getHighlightedText";
import { PaymentStatus } from "../../constants/globalConstants";
import { getLatestTransaction } from "../../modules/getLatestTransaction";
import ReconcileList from "../../common/PageLayout/components/ReconcileList";
import { CustomDrawer } from "../../common/CustomDrawer";

function TransactionsTable({ isViewOnly }: any) {
  const [loading, setLoading] = React.useState(false);
  const {
    transactions: _transactions,
    selectedBank,
    fetchLinkedBanks,
    uploadModal,
    showUploadModal,
  } = useBanksContext();
  const [transactions, setTransactions] = React.useState<any>(_transactions);
  const [error, setError] = React.useState("");
  const { externalAccounts } = useAuth();
  const [opened, { open, close }] = useDisclosure(false);
  const [openReconcileModal, setOpenReconcileModal] = useState(false);
  const [matchedInvoices, setMatchedInvoices] = useState([]);
  const [currentTransaction, setCurrentTransaction] = useState<any>(null);
  const [totalStatements, setTotalStatements] = useState(0);
  const [totalReconciled, setTotalReconciled] = useState(0);

  useEffect(() => {
    if (_transactions) {
      let modifiedTxns = _transactions.map((txn: any) => {
        let contactTypes = getContactTypes(txn?.contact?.displayName);
        return {
          ...txn,
          contact: txn?.contact || undefined,
          selectedContact: undefined,
          contactTypes: [],
          // selectedContactType: contactTypes[0]?.value || "",
          selectedContactType: txn?.selectedContactType,
          searchText: txn?.contact?.displayName || "",
        };
      });

      setTransactions(modifiedTxns);
    }
  }, [_transactions]);

  const getContactTypes = (searchText: string) => {
    let contact = externalAccounts.find(
      (contact: any) => contact.displayName === searchText
    );

    if (contact) {
      let types = contact.types.map((type: string) => ({
        value: type,
        label: type,
      }));

      return types;
    } else return [];
  };

  const onSave = async () => {
    let txnsWithContacts = transactions.map((txn: any) => {
      let contact = externalAccounts.find(
        (contact: any) => contact.displayName === txn.searchText
      );

      return {
        ...txn,
        contact: contact
          ? {
              ...contact,
              invoices: [],
            }
          : undefined,
      };
    });
    setLoading(true);

    const { success } = await mapTxnsToContacts({
      transactions: txnsWithContacts,
    });
    // setTimeout(() => {
    setLoading(false);
    // }, 1500);
    fetchLinkedBanks();

    if (success) {
      showNotification({
        color: "teal",
        message: `Changes saved successfully!`,
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
    } else {
      showNotification({
        color: "red",
        message: `Error saving changes!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedTxns, setSelectedTxns] = useState<string[]>([]);

  useEffect(() => {
    // Update the selectAll state when the selectedTxns change
    setSelectAll(
      selectedTxns.length > 0 && selectedTxns.length === transactions.length
    );
  }, [selectedTxns, transactions]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    txnId: string
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTxns([...selectedTxns, txnId]);
    } else {
      setSelectedTxns(selectedTxns.filter((id) => id !== txnId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedTxns(transactions.map((txn: any) => txn.id));
    } else {
      setSelectedTxns([]);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete the selected " +
          selectedTxns.length +
          " transactions(s)?"
      )
    ) {
      const { success } = await deleteTransactions({
        ids: selectedTxns,
        bankId: selectedBank?.bankId,
      });

      if (success) {
        showNotification({
          title: "Success",
          message: "Transactions(s) deleted successfully!",
          color: "green",
        });
        fetchLinkedBanks();
      } else {
        showNotification({
          title: "Error",
          message: "Could not delete transactions(s)",
          color: "red",
        });
      }
    }
  };

  const handleUnReconciledInvoices = async (transactionId: any) => {
    const currentTransaction = transactions.find(
      (txn: any) => txn.id === Number(transactionId)
    );

    setCurrentTransaction(currentTransaction);

    try {
      const relatedInvoices = await axios.post(
        configs.urls.BASE_URL +
          ROUTES.Cashflow.Base +
          "/balanceHistoryBank/getRelatedInvoices",
        { transactionId: transactionId },
        {
          withCredentials: true,
        }
      );

      setMatchedInvoices(relatedInvoices?.data?.data);
    } catch (err: any) {
      console.log("Error while fetching related invoices: ", err.message);
    } finally {
      setOpenReconcileModal(true);
    }
  };

  const onCloseModal = () => {
    setMatchedInvoices([]);
    setOpenReconcileModal(false);
  };

  const handleReconcile = async (
    transactionId: string,
    invoiceId: string,
    amountPaid: string
  ) => {
    try {
      setLoading(true);
      const response = await axios.post(
        configs.urls.BASE_URL +
          ROUTES.Cashflow.Base +
          "/balanceHistoryBank/reconcileTransaction",
        { transactionId, invoiceId, amountPaid },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setTotalStatements((prev) => prev - parseFloat(amountPaid));
        setTotalReconciled((prev) => prev + parseFloat(amountPaid));

        const updatedTransactions = transactions.map((txn: any) =>
          txn.id === Number(transactionId)
            ? { ...txn, isReconciled: true, reconciledAmount: amountPaid }
            : txn
        );

        setTransactions(updatedTransactions);
        showNotification({
          message: "transaction reconciled successfully!",
          color: "teal",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error reconciling transaction:", error);
      showNotification({
        color: "red",
        message: "Error reconciling transaction",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } finally {
      onCloseModal();
      setLoading(false);
      fetchLinkedBanks();
    }
  };

  const renderCardChildren = (bill: any) => (
    <>
      <div style={{ position: "relative" }}>
        {/* Header */}
        <div className="flex-direction-row is-justify-content-space-between">
          <div className="flex-direction-row">
            <figure className="image mr-3" style={{ width: 40, height: 40 }}>
              <img
                className="is-rounded"
                src={IMAGES.company_logo_placeholder}
                alt=""
              />
            </figure>
            <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
              {/* {getHighlightedText(cutLengthyString(bill?.name, 25), searchText)} */}
              {bill?.name}
            </div>
          </div>

          {bill.status === "Unpaid" &&
            moment(bill.dueDate).toISOString() < moment().toISOString() && (
              <button className="overdue-status">Overdue!</button>
            )}

          {bill.status === "PartiallyPaid" && (
            <button className="overdue-status orange">Partially Paid</button>
          )}
        </div>

        {/* Details */}
        <div className="flex-direction-row-space-between date-amount-container">
          <div>
            <div className="card-date">
              {bill.status === PaymentStatus.Paid
                ? getHighlightedText(
                    "Paid on " +
                      moment(
                        getLatestTransaction(bill?.linkedTransactions)
                          ?.paidOrFailedAt
                      ).format("MMM, DD, YYYY"),
                    ""
                  )
                : getHighlightedText(
                    "Due " +
                      `${moment(bill.expectedDueDate).format("MMM, DD, YYYY")}`,
                    ""
                  )}
            </div>
            <div className="card-code">
              {_.isEmpty(bill.invoiceNumber) || !bill.invoiceNumber.trim() ? (
                <i>No invoice number.</i>
              ) : (
                getHighlightedText(`#${bill.invoiceNumber}`, "")
              )}
            </div>
          </div>
          <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
            {bill.currency || "AED"}{" "}
            {getHighlightedText(
              formatCurrency(parseFloat(bill?.amountTotal || "0"), 2) || "",
              ""
            )}
          </div>
        </div>
        {bill.image && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        )}
      </div>

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Button
          disabled={loading}
          style={{
            backgroundColor: COLORS.greenPrimary,
            color: "white",
          }}
          onClick={() => {
            const transactionId = String(currentTransaction?.id);
            const invoiceId = String(bill?.id);
            const amountPaid = currentTransaction?.amount
              ? String(currentTransaction?.amount)
              : "0";

            handleReconcile(transactionId, invoiceId, amountPaid);
          }}
        >
          Reconcile
        </Button>
      </div>
    </>
  );

  return (
    <Fragment>
      <CustomDrawer
        zIndex={1000}
        opened={openReconcileModal}
        onBack={onCloseModal}
        onClose={onCloseModal}
        title={"Reconcile Bank Statement"}
      >
        <div
          className="reconciliation-layout"
          style={{
            display: "flex",
            gap: "20px",
            height: "90vh",
          }}
        >
          <div
            className="bank-statement-column"
            style={{
              flex: 1,
              border: "1px solid #ddd",
              background: "#fff",
              borderRadius: "10px",
              padding: "10px",
              overflowY: "scroll",
            }}
          >
            <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Bank Statement
            </h4>
            <div
              style={{
                backgroundColor: "#f9f9f9",
                padding: "15px",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              <p>
                <strong>Description:</strong>{" "}
                {currentTransaction?.counterparty_name}
              </p>
              <p>
                <strong>Contact:</strong>{" "}
                {currentTransaction?.contact
                  ? currentTransaction?.searchText
                  : "Not Found"}
              </p>
              <p>
                <strong>Amount:</strong> {currentTransaction?.currency}{" "}
                {currentTransaction?.amount}
              </p>
              <p>
                <strong>Date:</strong> {currentTransaction?.date}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                {currentTransaction?.isReconciled ? (
                  <span
                    style={{
                      color: "green",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Reconciled
                  </span>
                ) : (
                  <span
                    style={{
                      color: "orange",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Unreconciled
                  </span>
                )}
              </p>
            </div>
          </div>
          <div
            className="related-invoices-column"
            style={{
              flex: 2,
              border: "1px solid #ddd",
              borderRadius: "10px",
              padding: "10px",
              overflowY: "scroll",
            }}
          >
            <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Matched Invoices
            </h4>
            <ListContainerStyled
              className="custom-scrollbar"
              style={{
                overflowY: "scroll",
                height: "100%",
                overflowX: "hidden",
              }}
            >
              {matchedInvoices.length > 0 ? (
                matchedInvoices.map((item: any, index: number) => (
                  <div
                    key={`${index + item.name}`}
                    style={{ marginBottom: "10px" }}
                  >
                    <ReconcileList
                      isViewOnly={isViewOnly}
                      data={item}
                      renderItem={renderCardChildren}
                    >
                      {renderCardChildren}
                    </ReconcileList>
                  </div>
                ))
              ) : (
                <p>No related invoices found.</p>
              )}
            </ListContainerStyled>
          </div>
        </div>
      </CustomDrawer>

      <div className="is-flex is-flex-direction-row is-justify-content-space-between ">
        <BankTitle style={{ flex: 1, fontSize: 24 }}>
          {selectedBank?.bankName}
        </BankTitle>
        <div className="is-flex is-flex-direction-row is-align-items-flex-end is-gap-1">
          <div
            style={{
              alignSelf: "flex-end",
              whiteSpace: "nowrap",
              fontWeight: "600",
              fontSize: 12,
            }}
          >
            {selectedTxns.length > 0
              ? selectedTxns.length + " selected"
              : transactions.length + " transactions"}
          </div>
          <div>
            <Button
              disabled={isViewOnly}
              style={{
                backgroundColor: isViewOnly
                  ? COLORS.btnDisabled
                  : COLORS.greenBlue,
                color: "white",
                width: 40,
                height: 38,
              }}
              onClick={() => showUploadModal(true)}
            >
              <UploadIcon src={ICONS.upload} alt="upload" />
            </Button>
          </div>
          <PrimaryButton
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
            }}
            disabled={!selectedTxns.length || isViewOnly}
            onClick={handleDelete}
          >
            Delete
          </PrimaryButton>
          <PrimaryButton
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
            }}
            disabled={isViewOnly || transactions.length === 0}
            onClick={onSave}
            loading={loading}
          >
            Save to cashflow
          </PrimaryButton>
        </div>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"></div>
        <LoadingOverlay
          visible={loading}
          loaderProps={{
            color: COLORS.greenBlue,
            variant: "oval",
          }}
        />
        <Card
          className="table-container mb-3"
          style={{
            position: "relative",
          }}
        >
          {transactions.length === 0 ? (
            <NoTxn>No transactions found for this bank.</NoTxn>
          ) : null}
          <div
            className="table-container custom-scrollbar mb-3"
            style={{
              height: "calc(100vh - 280px)",
              overflowY: "scroll",
            }}
          >
            <table
              style={{ borderTop: "1px solid #e0e0e0" }}
              className="table is-bordered is-striped is-hoverable is-fullwidth is-relative "
            >
              <thead>
                <tr
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 9,
                  }}
                >
                  <TableHeader>
                    <Checkbox
                      disabled={isViewOnly}
                      indeterminate={
                        selectedTxns.length > 0 &&
                        selectedTxns.length < transactions.length
                      }
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </TableHeader>
                  <TableHeader>Date </TableHeader>
                  <TableHeader>Description</TableHeader>
                  <TableHeader>Contact</TableHeader>
                  <TableHeader>Contact type</TableHeader>
                  <TableHeader>Amount</TableHeader>
                  <TableHeader>Currency</TableHeader>
                  <TableHeader>Running Balance</TableHeader>
                  <TableHeader>Reconciliation Status</TableHeader>
                </tr>
              </thead>
              <tbody>
                {transactions
                  .sort(
                    (a: any, b: any) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .map((transaction: any) => {
                    let contactTypes = getContactTypes(transaction.searchText);
                    let defaultType = contactTypes.length
                      ? contactTypes[0]
                      : {};
                    const isChecked = selectedTxns.includes(transaction.id);

                    return (
                      <tr key={transaction.id}>
                        <TableData style={{ textAlign: "center", width: 20 }}>
                          <Checkbox
                            disabled={isViewOnly}
                            styles={{
                              body: {
                                paddingRight: 0,
                              },
                            }}
                            checked={isChecked}
                            onChange={(event) =>
                              handleCheckboxChange(event, transaction.id)
                            }
                          />
                        </TableData>
                        <TableData className="p-3">
                          {moment(transaction.date).format("MMM DD, YYYY")}
                        </TableData>
                        <TableData
                          className="p-3"
                          width={20}
                          title={transaction.counterparty_name}
                          style={{
                            minWidth: 400,
                            // whiteSpace: "nowrap",
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                          }}
                        >
                          {!transaction.counterparty_name
                            ? "N/A"
                            : transaction.counterparty_name}
                        </TableData>
                        <SelectContact
                          isViewOnly={isViewOnly}
                          item={transaction}
                          onChangeContact={(contact) => {
                            // let contactTypes = getContactTypes(value);
                            let contactTypes =
                              contact?.types.map((type: string) => ({
                                value: type,
                                label: type,
                              })) || [];

                            // console.log("contactTypes", contactTypes);
                            setTransactions((prev: any) => {
                              const modifiedTxns = prev.map((txn: any) => {
                                if (txn.id === transaction.id) {
                                  return {
                                    ...txn,
                                    searchText: contact?.displayName,
                                    selectedContactType: contactTypes.length
                                      ? contactTypes[0]?.value
                                      : "",
                                  };
                                }
                                return txn;
                              });
                              return modifiedTxns;
                            });
                          }}
                          onChangeType={(value: string) => {
                            setTransactions((prev: any) => {
                              const modifiedTxns = prev.map((txn: any) => {
                                if (txn.id === transaction.id) {
                                  return {
                                    ...txn,
                                    selectedContactType: value,
                                  };
                                }
                                return txn;
                              });
                              return modifiedTxns;
                            });
                          }}
                        />

                        <TableData className="p-3">
                          {transaction.amount}
                        </TableData>
                        <TableData className="p-3">
                          {transaction.currency}
                        </TableData>
                        <TableData className="p-3">
                          {formatCurrency(
                            parseFloat(transaction?.running_balance || "0"),
                            2
                          )}
                        </TableData>
                        <TableData className="p-3">
                          {transaction?.isReconciled ? (
                            <span
                              style={{
                                color: "green",
                                fontWeight: "700",
                                cursor: "pointer",
                              }}
                            >
                              Reconciled
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "orange",
                                fontWeight: "700",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleUnReconciledInvoices(transaction.id);
                              }}
                            >
                              Unreconciled
                            </span>
                          )}
                        </TableData>
                      </tr>
                    );
                  })}
              </tbody>
              {/* No content */}
            </table>
          </div>
        </Card>
      </div>

      <UploadCSVModal
        open={uploadModal}
        onClose={(uploadDone) => {
          showUploadModal(false);
          if (uploadDone) {
            fetchLinkedBanks();
          }
        }}
        bank={{ name: selectedBank?.bankName, id: selectedBank?.bankId }}
      />
    </Fragment>
  );
}

export default TransactionsTable;

const TableHeader = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  /* :first-child {
    min-width: 120px;
  } */
`;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;

const StyledSelect = styled(Select)`
  .mantine-Select-input {
    font-size: 14px;

    ::placeholder {
      color: #a30202fc;
    }
  }
`;

const UploadIcon = styled.img`
  width: 17px;
  height: 20px;
`;

const BankTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
`;

const NoTxn = styled.div`
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ListContainerStyled = styled.div`
  /* blur at the end of list if scroll is not at the end */
  /* ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  } */
  /* &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px; 
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.7),
      red
    ); 
    filter: blur(10px); 
  } */
`;
