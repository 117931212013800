// import "../styles.css";

import React, { useState, useEffect } from "react";
import { Bar, Chart, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import _, { cloneDeep } from "lodash";
import Card from "../Card/Card";
import { CardSubTitle } from "../CardSubTitle";
import { GraphLegend } from "../GraphLegend";
import { MONTHS_LIST } from "../../constants/globalConstants";
import { getDateRangeMonths } from "../../modules/getDateRangeMonths";
import { COLORS } from "../../constants/theme";
import { CardTitle } from "../CardTitles";
import { DateRangePicker } from "@mantine/dates";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface LegendType {
  title: string;
  value: string;
  color: string;
}
interface DataSetType {
  label: string;
  data: any[];
  borderColor: string;
}
interface LineChartType {
  title: string;
  subTitle: string;
  legends: LegendType[];
  dataSets: DataSetType[];
  tension?: any;
  pointRadius?: any;
  isLineChart?: boolean;
}

function LineChart({
  title,
  subTitle,
  legends,
  dataSets,
  tension = 0.3,
  pointRadius = 0,
  isLineChart = false,
}: LineChartType) {
  const date = getDateRangeMonths(true);
  const graphData = dataSets.map((data) => ({ ...data, tension, pointRadius }));

  const dataSetClone = cloneDeep(graphData);

  const [dateValue, setDateSetValue] = useState<[any, any]>([
    new Date(date.from),
    new Date(date.to),
  ]);

  const [filteredMonths, setFilteredMonths] = useState<string[]>(date.months);
  const [filteredDataSets, setFilteredDataSets] = useState<any>(dataSetClone);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const handleDateChange = (newDateValue: [Date, Date]) => {
    setDateSetValue(newDateValue);

    if (newDateValue && newDateValue[0] && newDateValue[1]) {
      const [startDate, endDate] = newDateValue;

      const filteredMonths = date.months.filter((month) => {
        const [monthName, yearSuffix] = month.split(" '");
        const monthIndex = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ].indexOf(monthName);
        const year = parseInt(`20${yearSuffix.trim()}`, 10);

        const monthDate = new Date(year, monthIndex, 1);
        return monthDate >= startDate && monthDate <= endDate;
      });

      const filteredDataSets = dataSets.map((dataSet) => ({
        ...dataSet,
        data: dataSet.data.slice(0, filteredMonths.length),
      }));

      setIsFiltered(true);
      setFilteredMonths(filteredMonths);
      setFilteredDataSets(filteredDataSets);
    }
  };

  return (
    <Card className="m-2" style={{ padding: "41px", height: "65vh" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CardTitle title={subTitle} />
          <CardSubTitle title={title} />
        </div>
        {/* <div
          style={{
            minWidth: "300px",
          }}
        >
          <DateRangePicker
            minDate={new Date(date.from)}
            maxDate={new Date(date.to)}
            amountOfMonths={2}
            label="Filter Cashflow"
            placeholder="Pick date range"
            value={dateValue}
            onChange={handleDateChange}
          />
        </div> */}
      </div>

      <div className="legend-container mt-6">
        {legends.map(({ title, value, color }) => (
          <GraphLegend title={title} value={value} color={color} />
        ))}
      </div>

      {isLineChart ? (
        <Line
          height={"55vh"}
          width={"80vw"}
          options={{
            responsive: true,
            maintainAspectRatio: true,

            interaction: {
              intersect: false,
              mode: "index",
            },

            plugins: {
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels: isFiltered ? filteredMonths : date.months,
            datasets: isFiltered ? filteredDataSets : graphData,
          }}
        />
      ) : (
        <Chart
          type="bar"
          className="mt-5"
          height={"120%"}
          data={{
            // labels: chartData?.months,
            datasets: [
              {
                type: "line" as const,
                label: "Closing Balance", //appTheme.COLORS.yellow
                borderColor: isFiltered
                  ? filteredDataSets[0].borderColor
                  : dataSets[0].borderColor,
                borderWidth: 3,
                data: isFiltered ? filteredDataSets[0].data : dataSets[0].data,
                tension: 0.4,
                pointRadius: 2,
              },
              {
                type: "bar" as const,
                label: "Cash In",
                backgroundColor: isFiltered
                  ? filteredDataSets[1].borderColor
                  : dataSets[1].borderColor,
                borderColor: isFiltered
                  ? filteredDataSets[1].borderColor
                  : dataSets[1].borderColor,
                data: isFiltered ? filteredDataSets[1].data : dataSets[1].data,
                borderWidth: 2,
                barThickness: 12,
              },
              {
                type: "bar" as const,
                label: "Cash Out",
                backgroundColor: isFiltered
                  ? filteredDataSets[2].borderColor
                  : dataSets[2].borderColor,
                borderColor: isFiltered
                  ? filteredDataSets[2].borderColor
                  : dataSets[2].borderColor,
                data: isFiltered ? filteredDataSets[2].data : dataSets[2].data,
                borderWidth: 2,
                barThickness: 12,
              },
              {
                type: "bar" as const,
                label: "Unreconciled",
                backgroundColor: isFiltered
                  ? filteredDataSets[3].borderColor
                  : dataSets[3].borderColor,
                borderColor: isFiltered
                  ? filteredDataSets[3].borderColor
                  : dataSets[3].borderColor,
                data: isFiltered ? filteredDataSets[3].data : dataSets[3].data,
                borderWidth: 2,
                barThickness: 12,
              },
            ],
            labels: isFiltered ? filteredMonths : date.months,

            // datasets: graphData,
          }}
          options={{
            elements: {
              point: {
                radius: 4,
                borderWidth: 3,
                borderColor: "#fff",
                hoverRadius: 7,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                  borderColor: "transparent",
                },
                ticks: {
                  color: "#5c5c5e",
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                  borderColor: "transparent",
                },
                ticks: {
                  color: "#5c5c5e",
                  callback: function (_value) {
                    let value: number;
                    if (typeof _value === "string") {
                      value = parseFloat(_value);
                    } else {
                      value = _value;
                    }

                    if (value > 0) {
                      return Intl.NumberFormat("en", {
                        notation: "compact",
                      }).format(value);
                    } else if (value < 0) {
                      return `(${Intl.NumberFormat("en", {
                        notation: "compact",
                      }).format(Math.abs(value))})`;
                    } else {
                      return 0;
                    }
                  },
                },
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default LineChart;
